﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.container-fluid {
    padding-left: $space-xs + $space-sm;
    padding-right: $space-xs + $space-sm;

    @include media-breakpoint-up(sm) {
        padding-left: $space-xl;
        padding-right: $space-xl;
    }
}

.row{
    @include media-breakpoint-down(sm){
        margin-left: 0;
        margin-right: 0;
    }
}
.card-header:first-child {
    background-color: $color-white;
    border-radius: 0;
}


.list-group-item {
    background-color: transparent;
    border-radius: 0 !important;
    border: none;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    padding: $space-md $space-sm;

    .collapse-heading {
        display: block;
        font-weight: bold;
        @include typo(heading);

        &:after {
            display: block;
            position: absolute;
            width: 13px;
            height: 1px;
            content: "";
            background-image: url(/Assets/svg/plus.svg);
            right: 0;
            background-repeat: no-repeat;
            top: 25px;
            transition: all .3s ease-in-out;
            background-position: center center;
            background-color: $color-black;
            transform: rotate(180deg);
        }

        &.collapsed {
            &:after {
                height: 11px;
                background-image: url(/Assets/svg/plus.svg);
                background-color: transparent;
            }
        }
    }
    .collapse-content{
        padding-top: $space-md;
        img{
            max-width: 100%!important;
            height: auto!important;
        }
    }
}