/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
@import '../../node_modules/bootstrap/scss/bootstrap';
@import "./imports/_imports";

@font-face {
    font-family: Akkurat;
    src: url('/Assets/fonts/Akkurat.otf');
}

@font-face {
    font-family: Akkurat;
    font-weight: bold;
    src: url('/Assets/fonts/Akkurat-Bold.otf');
}

.page-body {
    position: relative;
    margin-top: 80px;
    margin-bottom: $space-xl*2;

    /*sticky footer*/
    flex: 1;

    @include media-breakpoint-up(sm) {
        margin-top: 116px;
    }
}
.StartPage-view{
    .page-body{
        margin-bottom: 0;
    }
}

body {
    /*sticky footer*/
    display: flex!important;
    min-height: 100vh;
    flex-direction: column;
    font: 14px/20px Akkurat, arial;
    color: #333;
    background-color: $color-grey;

    @include media-breakpoint-up(sm) {
        background-color: $color-grey;
    }

    .page-border {
        position: fixed;
        top: 0;
        left: 0;
        border-left: $space-xs + $space-sm solid $color-white;
        border-right: $space-xs + $space-sm solid $color-white;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        @include media-breakpoint-up(sm) {
            border-left: $space-xl solid $color-white;
            border-right: $space-xl solid $color-white;
        }
    }
}




.bg-light {
    background-color: rgba(255,255,255,0.1) !important;
    /*background-color:#FFF;
    color:#333;*/
}

.bg-dark {
    background-color: rgba(0,0,0,0.2) !important;
}








.btn-animation {
    -webkit-transition: .6s ease-out;
    transition: .6s ease-out;
}


$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #62D175;
    }

    25% {
        stroke: #25A8A0;
    }

    50% {
        stroke: #EB6568;
    }

    75% {
        stroke: #92518D;
    }

    100% {
        stroke: #62D175;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}



[v-cloak] {
    display: none;
}

ol {
    margin-top: 20px;
    margin-left: 0px;
    padding-right: 0;
    padding-left: 0;
    list-style-type: none;

    li {
        counter-increment: step-counter;
        padding-left: 40px;
        padding-top: 0px;
        padding-bottom: 17px;
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 0px;
            max-width: 30px;
            max-height: 30px;
            width: 30px;
            height: 30px;
            overflow: hidden;
            content: counter(step-counter);
            background-color: #FFF;
            color: $color-green;
            line-height: 30px;
            text-align: center;
            font-family: $font-open-sans;
            font-size: 17px;
            border-radius: 50%;
        }
    }
}

@import 'shared/footer';
@import 'shared/header-cart';
@import 'shared/header';
@import 'shared/hero';
@import 'shared/modules';
@import 'shared/productnavigaton';
@import 'shared/navigation';
@import 'shared/megamenu';
@import 'shared/mobnav';
@import 'shared/views';
@import 'shared/productlist';
@import 'shared/cookieconsent';
