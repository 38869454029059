﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />


.navigation {
    .menu-burger {
        width: 30px;
        margin-top: 81px;
        cursor: pointer;
        display: block;

        @include media-breakpoint-up(md) {
           
            display: none;
        
        }

        span {
            display: block;
            height: 2px;
            background-color: #000;
            margin-top: 5px;
        }
    }

    ul {
        list-style: none;
    }

    .level1 {
        display: none;

        @include media-breakpoint-up(md) {
            
            display: flex;
            margin-top: 81px;
            padding-left: 0;
        }

        li {
            margin-right: 28px;
            position: relative;

            a {
                display: block;
                padding-bottom: 30px;
            }

            &.has-children:hover {
                .level2 {
                    display: flex;
                }
            }

            &.has-children {
                padding-right: 22px;
                width: 100%;
                position:absolute;
            }

            &.has-children::after {
                content: "";
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 15px;
                background-image: url('/Assets/svg/chevron_down.svg');
                background-position: center center;
                background-size: cover;
            }

            ul {
                &.level2 {
                    display: none;
                    position: absolute;
                    top: 35px;
                    left: 0;
                    background-color: $color-grey;
                    padding: 15px;




                    li {
                        padding: 0;
                        margin-right: 20px;

                        a {
                            display: block;
                            padding-bottom: 0px;
                        }


                        &.has-children {
                            padding-right: 0px;
                        }
                    }



                    li::after {
                        content: none !important;
                        background-image: none !important;
                    }
                }
            }
        }
    }



    .level3 {
        display: none;
    }
}

.menu-open{
    .navigation{
        margin-top:30px;
        background-color:$color-grey;
        ul{
            &.level1{
                display:block;
            }
        }
    }
}