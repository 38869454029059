﻿textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: none;   
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: unset!important;
}


input {
   
    border-radius: 0;
}

input[type=text] {
    -webkit-appearance: none;
    border-radius: 20px;
    border: none;
    padding: 7px 20px 7px 20px;
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
        width: 230px;
    }
}

$color-placeholder: #999

/* do not group these rules */
::-webkit-input-placeholder
{
    color: $color-placeholder;
}

*:-moz-placeholder {
    /* FF 4-18 */
    color: $color-placeholder;
}

*::-moz-placeholder {
    /* FF 19+ */
    color: $color-placeholder;
}

*:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-placeholder;
}
