﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />

.ProductCategory-view, .Products-view, .Brand-view {
    .page-body {
        margin-top: 80px;

        @include media-breakpoint-up(sm) {
            margin-top: 116px;
        }

        .product-list {
            padding-top: $space-xl*2.5;
        }

        .filter-container {
            position: fixed;
            z-index: 2000;
            background-color: $color-white;
            transition: all .3s ease-in-out;
            padding: $space-xs 0;
            width: 100%;
            top: 80px;

            @include media-breakpoint-up(sm) {
                top: 116px;
            }

            &.fixed {
                position: fixed;
                padding: $space-xs 0;
                top: 0;
                transition: all .3s ease-in-out;
                background-color: $color-white;
            }
        }

        .category-filter {
            flex: auto;
            left: 0;
            position: relative;
            margin-top: $space-xl;
            justify-content: center;
            margin-top: 0;
            width: 100%;

            a {
                display: block;
                position: relative;
                text-align: center;
                padding: $space-sm $space-sm;
                animation: fadeInFilterItem 0.3s ease-out both;
                transition: all .2s ease-in-out;
                width: auto;
                border-radius: 2px;
                background-color: $color-grey-dark;
                color: $color-white;
                margin: $space-xs;
                cursor: pointer;

                @include media-breakpoint-up(sm) {
                    padding: $space-xs $space-sm;
                }

                &.sort-btn {
                    padding-right: $space-lg;
                    background-color: $color-black;
                    border: 1px solid $color-grey-dark;
                    background-color: $color-white;
                    color: $color-grey-dark;
                    margin-right: $space-xs;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 17px;
                        right: 9px;
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid $color-grey-dark;
                        transition: all .3s ease-in-out;

                        @include media-breakpoint-up(sm) {
                            top: 11px;
                        }
                    }

                    &.desc {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }

                    &:hover {
                        background-color: $color-grey;
                        color: $color-grey-dark;
                    }
                }

                @include media-breakpoint-up(sm) {
                    padding: $space-xs $space-sm;
                }

                &:hover {

                    @include media-breakpoint-up(sm) {
                        background-color: $color-green;
                        color: $color-white;
                    }
                }

                &.active {
                    background-color: $color-green;
                    color: $color-white;
                }
            }
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fadefilter-enter-active, .fadefilter-leave-active {
    transition: all .5s;
}

.fadefilter-enter, .fadefilter-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    height: 0;
}
@keyframes fadeInFilterItem {
    from {
        opacity: 0;
        transform: translate3d(-5%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
