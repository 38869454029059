﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.brand-module {
    margin-top: $space-lg;
    padding-top: $space-lg;
    background-color: $color-white;

    .brands {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 1 100%;
        padding-top: $space-md;
        padding-bottom: $space-sm;

        .brand-item {
            position: relative;
            background-repeat: no-repeat;
            background-color: $color-white;
            background-position: center center;
            padding: 0 $space-sm;
            border: none;
            width: 30%;
            height: 40px;
            line-height: 40px;
            margin: 0 10px 10px 0;
            background-size: 50%;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
            display: block;
            background-size: 50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #ffffff;
            padding: 30px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                max-height: 600px;
                background-color: $color-purple;
                content: "";
                opacity: 0;
                transition: all .3s ease-in-out;
            }

            &:hover {

                &:before {
                    opacity: 0.2;
                }
            }

            @include media-breakpoint-up(sm) {
                margin: 0 30px 30px 0;
                height: 100px;
                width: 25%;
                line-height: 150px;
            }

            @include media-breakpoint-up(md) {
                margin: 0 30px 30px 0;
                height: 130px;
                width: 20%;
                line-height: 130px;
            }
        }
    }
}
