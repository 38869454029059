﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.product-list {
    .filter-list-enter-active, .filter-list-leave-active {
        transition: all .3s;
    }

    .filter-list-enter, filter-list-leave-to, .filter-list-leave-active {
        opacity: 0;
        transform: scale(0.5);
        transition: all .3s;
    }

    .filter-list-move {
        transition: transform .5s;
    }

    .product-item {
        margin-bottom: $space-lg;
        /*animation: fadeIn 0.3s ease-out both;
        animation-delay: .2s;*/
        a {
            /*position:relative;
            display: block;
            padding: $space-md;
            background-color: $color-white;*/
            .product-image {
                position: relative;
                background-color: $color-white;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                margin-bottom: 0;

                @include media-breakpoint-up(sm) {
                    margin-bottom: $space-md;
                }

                .badge-img {
                    position: absolute;
                    top: -10%;
                    left: -10%;
                    width: 60%;
                    height: 60%;

                    @include media-breakpoint-up(sm) {
                        left: -8%;
                        top: -8%;
                        width: 40%;
                        height: 40%;
                    }
                }

                .badge-new {
                    position: absolute;
                    text-align: center;
                    top: 5%;
                    left: 5%;
                    width: 30%;
                    height: 30%;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    background-color: $color-green;
                    justify-content: center;
                    transform: rotate(-20deg);

                    span {
                        color: $color-black;
                        font-weight: bold;
                        font-size: 4vmin;
                    }

                    @include media-breakpoint-up(sm) {
                        span {
                            font-size: 4vmin;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        span {
                            font-size: 6vmin;
                        }
                    }
                }
            }

            .product-brand {
                margin-bottom: 0;
                @include typo(xs);
                text-transform: uppercase;
                display: inline-block;
            }

            .name-row {
                display: flex;
                justify-content: space-between;
                margin-top: $space-sm;

                .product-name {
                    font-weight: bold;
                }

                .price {
                    color: $color-green;

                    &.lowered {
                        color: $color-red;

                        .regular-price {
                            color: $color-green-light;
                            text-decoration: line-through;
                            padding-right: $space-xs;
                        }
                    }
                }
            }
        }

        h2 {
            @include typo(sm);
            text-transform: uppercase;
        }

        p {
            line-height: 1;
        }
    }
}
