﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.Brand-view {
    padding-top: $space-lg*2.5;

    @include media-breakpoint-up(sm) {
        padding-top: $space-lg*3;
    }

    .page-body {
        .product-list {
            padding-top: $space-md;
        }
    }

    .text-desk {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    .list-group {
        display: block;


        @include media-breakpoint-up(sm) {
            display: none;
        }

        .list-group-item {
            background-size: contain;
            background-position: left center;
            background-repeat: no-repeat;

            .collapse-heading {
                height: 20px;

                img {
                    height: 50px;
                    position: absolute;
                    top: $space-xs;
                    left: 0;
                }
            }
            .brand-text{
                padding-top: $space-md;
            }
        }
    }

    .brand-img {
        background-position: top left;
        background-size: 80% auto;
        background-repeat: no-repeat;
        padding-bottom: 25%;
        background-position: center top;
    }
}
