﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
$font-open-sans: 'Open Sans', sans-serif;
body {
    font-family: Akkurat;
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
    color: black;
}
@mixin typo($size) {
    @if $size == xs {
        font-family: Akkurat;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        font-size: 0.75rem;
        line-height: 1.375rem;
        letter-spacing: 0;
    }
    @else if $size == sm {
        font-family: Akkurat;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        line-height: 1.75rem;
        letter-spacing: 0;
    }
    @else if $size == heading {
        font-family: Akkurat;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        line-height: 1.25rem;
        letter-spacing: 0;
    }
    @else if $size == md {
        font-family: Akkurat;
        font-weight: normal;
        font-size: 30px;
        line-height: 34px;
        font-size: 1.875rem;
        line-height: 2.125rem;

        @include media-breakpoint-up(sm) {
            font-family: Akkurat;
            font-weight: normal;
            font-size: 40px;
            line-height: 45px;
            font-size: 2.5rem;
            line-height: 2.813rem;
        }
    }
    @else if $size == lg {
        font-family: Akkurat;
        font-weight: normal;
        font-size: 80px;
        line-height: 85px;
        font-size: 5rem;
        line-height: 5.313rem;

        @include media-breakpoint-up(md) {
        }
    }
}

p {
    @include typo(sm);
}
h1 {
    @include typo(lg);
    text-transform: uppercase;
    font-size: 10vmin;
    line-height: 1
}
h2 {
    @include typo(md);
    margin-top:20px;
}
h3 {
    @include typo(sm);
}
a {
    color: $color-black;
    text-decoration: none;

    &:hover {
        color: $color-black;
        text-decoration: none;
    }
}
.content-text{
    a{
        text-decoration: underline;
    }
}