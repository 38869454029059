﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.Product-view {
    .btn-back {
        position: relative;
        padding-left: 30px;
        margin-bottom: $space-md;
        svg{
            left: 10px;
            top: 2px;
            position: absolute;
            fill: $color-white;
            width: 15px;
            height: 30px;
        }
    }

    .product {
        .product-info {
            position: relative;
            display: flex;
            flex-direction: column;

            .top {
                flex: 1;

                h1 {
                    text-transform: none;
                    font-size: 10vmin;

                    @include media-breakpoint-up(sm) {
                        font-size: 6vmin;
                    }
                }


                .price {
                    color: $color-green;

                    &.lowered {
                        color: $color-red;

                        .regular-price {
                            color: $color-green-light;
                            text-decoration: line-through;
                            padding-right: $space-xs;
                        }
                    }
                }
            }

            .variant-selection {
            }

            .out-of-stock {
                text-decoration: line-through;
                color: $color-grey-dark;
            }

            .btn {
            }
        }

        margin-top: 15px;

        @include media-breakpoint-up(sm) {
            margin-top: $space-lg;
        }

        .product-carousel {
        }

        .carousel-thumbnails {
            padding: 0;
            margin: 0;
            list-style: none;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row;
            justify-content: flex-start;
            line-height: 30px;
            margin-top: 5px;
            margin-left: -5px;
            margin-right: -5px;

            .thumbnail {
                position: relative;
                cursor: pointer;
                background-color: $color-white;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 5px;
                color: white;
                font-weight: bold;
                font-size: 1.5em;
                text-align: center;
                flex: 1 0 auto;
                height: auto;
                max-width: 100px;

                &:before {
                    content: '';
                    float: left;
                    padding-top: 100%;
                }

                &:after {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: $color-green;
                    content: "";
                    opacity: 0;
                    transition: all .3s ease-in-out;
                }

                &:hover {
                    &:after {
                        opacity: 0.2;
                    }

                    .txt {
                        p {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }

        .product-image {
            background-color: $color-white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            height: 0;
            width: 100%;
            padding-bottom: 100%;


            .badge-new {
                position: absolute;
                top: 20px;
                left: 20px;
                padding: 20px;
                border-radius: 50%;
                color: #FFF;
                background-color: #000;
            }
        }

        h2 {
            @include typo(sm);
            text-transform: uppercase;
        }

        .add-to-cart {
            margin-top: $space-xl;
            margin-bottom: 5px;
        }

        .product-footer {
            margin-top: $space-lg;
        }
    }

    .related-products {
        h2 {
            margin-top: 60px;
        }
    }
}
