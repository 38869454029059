﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.mobnav-active {
    /*overflow: hidden;*/
        /*body {
        margin: 0;
        height: 100%;*/
        /*overflow: hidden;*/
    
}

.mobile-stretch {
    position: fixed;
    top: 80px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    width: 100%;
    display: none;
}

.mob-nav {
    position: fixed;
    width: 100%;
    top: 80px;
    color: $color-white;
    display: none;
    left: 0;
    height: 100%;
    z-index: 9999;

    .nav-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    ul {
        position: relative;
        width: 100%;
        box-shadow: 0 19px 19px 0 rgba(0, 0, 0, 0.5);
        list-style: none;
        padding: 0;
        /*max-height: calc(100vh - 80px);*/
        background-color: transparent;
        border-bottom: $space-sm solid $color-white;
        margin-bottom: 80px;

        li {
            width: 100%;

            a {
                border-left: 15px solid #fff;
                border-top: 5px solid #fff;
                border-right: 15px solid #fff;
                animation: fadeIn 0.2s ease-out both;
                display: block;
                color: $color-black;
                text-align: center;
                width: 100%;
                height: 60px;
                line-height: 60px;
                /*-webkit-box-shadow: inset 0 -1px 0 0 $color-grey-dark;
                box-shadow: inset 0 -1px 0 0 $color-grey-darkest;*/
                background-color: $color-grey;

                &.collapse-heading {
                    display: block;
                    position: relative;

                    span {
                        position: relative;
                        display: inline-block;
                        top: 3px;
                        width: 20px;
                        height: 15px;
                        background-image: url(/Assets/svg/chevron_down.svg);
                        background-position: center center;
                        background-size: cover;
                    }
                }
            }

            .child-container {
                background-color: $color-white;

                .row {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex: 1 100%;
                    padding-top: $space-md;
                    padding-bottom: $space-sm;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-grey;
                        padding: 0 $space-sm;
                        border: none;
                        width: 30%;
                        min-height: 40px;
                        line-height: 25px;
                        margin: 0 5px 5px 0;

                        &.brand-logo {
                            box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
                            display: block;
                            background-size: 50%;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-color: $color-white;
                            padding: $space-lg;
                        }
                    }
                }
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(-10%, -10%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}



.nav-btn {
    position: absolute;
    top: 15px;
    right: 4px;
    width: 67px;
    height: 55px;
    cursor: pointer;
    padding: $space-sm;
    div {
        width: 47px;
        height: 6px;
        position: absolute;
        background-color: $color-black;
        transition: all .3s ease-in-out;

        &.line-top {
        }

        &.line-middle {
            margin-top: 14px;
        }

        &.line-bottom {
            margin-top: 28px;
        }
    }

    &.active {
        div {

            &.line-top {
                transform: rotate(45deg);
                margin-top: 14px;
            }

            &.line-middle {
                transform: rotate(-45deg);
            }

            &.line-bottom {
                transform: rotate(-45deg);
                opacity: 0;
                margin-top: 14px;
            }
        }
    }
}
