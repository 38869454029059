﻿//Brand colors
$color-green: rgba(0, 153, 73, 1.00);
$color-green-light: rgba(0, 153, 73, 0.60);
$color-grey: rgb(245,245,245);
$color-grey-dark: rgb(150,150,150);
$color-white: #ffffff;
$color-purple: #615cb8;
$color-black: #000000;
$color-red: #ff0000;
$main-color: #000000;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 20px;
$space-lg: 30px;
$space-xl: 40px;
