﻿
@import "_variables";
.btn {
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &:focus{
        outline: none !important;
        box-shadow: none;
    }

    &:active {
        outline: none !important;
        box-shadow: none;
    }
}
.btn-primary {
    background-color: $color-green;
    border:2px solid #FFF;
    transition: all .3s!important;

    &:hover, &:active {
        background-color: $color-green;        
        border-color: #FFF !important;
    }
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: $color-grey;
    border-color: $color-grey;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: $color-green-light;
    border-color: $color-grey;
    color: #666;

    &:hover {
        background-color: $color-green-light!important;
        border-color: $color-grey !important;
        color: #666;
        text-decoration: none;
    }
}

.add-to-cart {
    background-color: $color-green;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 #ADADAD;
    color: $color-white;
    height: 70px;
    line-height: 70px;
    padding: 0;
    border: none;
    width: 100%;
    padding-left: $space-lg;
    padding-right: $space-lg;

    &:hover {
        background-color: $color-green !important;
        color: $color-white;
        background-color: $color-green;
        line-height: 70px - $space-sm;
        text-decoration: underline;
    }
}
