﻿// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.Page-view {

    .page-hero {
        height: 40vh;
        background-size: cover;
        background-position: center center;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            height: 60vh;
        }

        .heading-container {
            position: absolute;
            bottom: $space-xl;
            width: 100%;
        }

        h1 {
            color: $color-white;
        }



        @include media-breakpoint-up(sm) {
            height: 60vh;
        }

        &:before {
            position: absolute;
            width: 100%;
            height: 70vh;
            display: block;
            max-height: 600px;
            background-color: $color-purple;
            content: "";
            opacity: 0.4;
            transition: all .3s ease-in-out;

            @include media-breakpoint-up(sm) {
                height: 60vh;
            }
        }
    }

    .page-content {
        img {
            max-width: 100% !important;
            height: auto !important;
        }

        .intro {
            font-size: 7vmin;
            line-height: 1;
            font-weight: bold;
            color: $color-black;

            @include media-breakpoint-up(md) {
                font-size: 4vmin;
            }
        }

        margin-top: $space-xl;
    }
}