﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    color: $color-white;
    z-index: 2001;
    background-color: $color-white;
    transition: all .3s ease-in-out;

    .logo {
        position: absolute;
        left: $space-xs + $space-sm;
        top: $space-md;
        height: 42px;
        width: 120px;
        display: block;
        /*opacity: 0.03;*/
        background-color: transparent;
        transition: all .3s ease-in-out;

        svg {
            height: 42px;
            width: 120px;
            transition: all .3s ease-in-out;
        }

        @include media-breakpoint-up(sm) {
            height: 53px;
            width: 150px;
            right: $space-md;
            left: auto;

            svg {
                height: 53px;
                width: 150px;
            }
        }

        @include media-breakpoint-up(md) {
            height: 77px;
            width: 221px;
            right: $space-md;
            left: auto;

            svg {
                height: 77px;
                width: 221px;
            }
        }
    }

    &.is-hidden {
        position: fixed;
        top: -80px;

        &.mob-nav-open {
            top: 0;

            @include media-breakpoint-up(sm) {
                top: -116px;
            }
        }

        @include media-breakpoint-up(sm) {
            top: -116px;
        }

        .logo {
            @include media-breakpoint-up(sm) {
                top: 122px;
                height: 40px;
                width: 120px;
                right: 0;
                /*transform: rotate(-90deg);*/
            }

            svg {
                height: 40px;
                width: 120px;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        height: 116px;
    }
}

.navbar {
}
