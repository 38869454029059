﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
$navHeight: 60px;
.product-navigation {
    margin-top: $space-lg*2;
    min-width: 100%;
    min-height: $navHeight;
    background-color: $color-white;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.20);
    border-radius: 50px;
    display: none;
    text-align: center;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: center;
        -webkit-flex-flow: row wrap;
    }

    a {
        transition: all .3s ease-in-out;
        height: $navHeight;
        line-height: $navHeight;
        display: block;
        padding: 0 $space-xl;
        flex-grow:1;
        &:hover {
            background-color: $color-green;
            color: $color-white;
        }

        &.active {
            line-height: $navHeight;
            background-color: $color-green;
            color: $color-white;
            border-radius: 100px;

            @include media-breakpoint-up(md) {
                border-radius: 0;
            }
        }
    }
}
