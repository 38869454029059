﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.footer {
    position: relative;
    width: 100%;
    padding-bottom: $space-md;
    padding-top: $space-md;
    background-color: $color-white;
    bottom: 0;
    z-index: 50;

    a {
        height: 40px;
        opacity: 0.5;
        transition: all .3s ease-in-out;
        padding: $space-sm;
        &:hover{
            opacity: 1;
        }
    }

    .social {
        img {
            width: 20px;
            height: 20px;
        }
    }

    .mail {
        img {
            width: 25px;
            margin-right: $space-sm;
        }
    }
}
