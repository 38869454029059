﻿.Confirm-view {
    p, div.td, td, span, a {
        line-height: 20px;
    }

    table {
    }

    table td {
        text-align: left;
        padding-bottom: 5px;
    }

    .center {
        /*text-align: center;*/
    }

    .logo {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .order-table {
        border: 1px solid #CCC;
        width: 100%;
    }

    .order-table th {
        padding: 8px;
        text-align: left;
        background-color: #CCC;
        font-weight: normal;
    }

    .order-table td {
        padding: 8px;
    }

    a {
        color: #000;
    }
}
