﻿// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.hero {
    .hero-img {
        position: relative;
        width: 100%;
        height: 70vh;
        display: block;
        max-height: 600px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition: all .3s ease-in-out;
        @include media-breakpoint-up(sm) {
            height: 60vh;
        }

        &:before {
            position: absolute;
            width: 100%;
            height: 80vh;
            display: block;
            max-height: 600px;
            background-color: $color-green;
            content: "";
            opacity: 0;
            transition: all .3s ease-in-out;

            @include media-breakpoint-up(sm) {
                height: 60vh;
            }
        }

        &:hover {
            &:before {
                opacity: 0.2;
            }

            .txt {
                p {
                    border-bottom: 1px solid $color-white;
                }
            }
        }

        .txt {
            color: $color-white;
            position: absolute;
            bottom: $space-xl;
            left: $space-md;

            @include media-breakpoint-up(sm) {
                width: 70%;
                left: $space-xl;
            }

            h1 {
                margin-bottom: $space-md;
                font-size: 8vmin;
                line-height: 1
            }

            p {
                margin-left: 0;
                transition: all .3s ease-in-out;
                display: inline;

                @include media-breakpoint-up(sm) {
                    margin-left: $space-sm;
                }
            }
        }
    }
}
