﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />

.cart-view {
    h1 {
        margin-top: 40px;
    }

    .cart {
        margin-top: 20px;

        .cart-row {
            display: flex;
            margin-bottom: 20px;

            .product-image {
                background-color: #FFF;
                padding: 10px;
                width: 70px;
                height: 70px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-origin: content-box;
                margin-right: 20px;

                @include media-breakpoint-up(sm) {
                    width: 100px;
                    height: 100px;
                }
            }

            .product-info {
                margin-right: 20px;
                flex: 1;

                .product-name {
                    font-weight: 700;
                }
            }

            .product-price {
                margin-right: 20px;
            }

            .product-delete {
                width: 70px;
                text-align: right
            }
        }

        .discount-section {
            padding: 20px 0 20px 0;
            border-top: 1px solid #CCC;

            form {
                display: flex;
                align-content: flex-end;
                justify-content: flex-end;
                align-items: center;

                .label {
                    flex: 1;
                    text-align: right;
                }

                .code {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border: 2px solid #FFF;
                }



                .btn {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0;
                    width: auto;
                }
            }
        }

        .pickup-section {
            padding: 20px 0 20px 0;
            border-top: 1px solid #CCC;
            .checkbox{
                width:15px;
                height:15px;
                border:1px solid #CCC;
            }
            label{
                cursor:pointer;
            }
        }

        .cart-sum {
            border-top: 1px solid #CCC;
            border-bottom: 1px solid #CCC;
            padding: 20px 0 20px 0;

            div {
                display: flex;

                .label {
                    flex: 1;
                }

                .value {
                }

                &.total-sum {
                    font-weight: bold;
                }
            }
        }
    }
}

.variant-selection {
    .variant-heading {
        font-weight: bold;
    }

    .variant {
        padding: 5px;
        background-color: #CCC;
        margin-right: 10px;
        border-radius: 2px;

        &.selected {
            background-color: $color-green;
            color: #FFF;
        }
    }
}
