﻿.sections {
    .section:nth-child(even) {
        @extend .bg-light;
        
    }
}

.section {
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

