﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.mega-menu {
    position: relative;
    width: 100%;
    top: 70px;
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    nav {
        padding-left: 40px;

        .top-level, .page {
            position: relative;
            margin-right: $space-lg;
        }

        .top-level {
            &::after {
                content: "";
                position: absolute;
                top: 2px;
                right: -18px;
                width: 20px;
                height: 15px;
                background-image: url('/Assets/svg/chevron_down.svg');
                background-position: center center;
                background-size: cover;
            }
        }
    }


    .panel-container {
        width: 100%;
        position: relative;
        background-color: $color-grey;
        min-height: 100px;
        top: 26px;
        display: none;

        .mega-panel {
            position: relative;
            background-color: $color-white;
            border-bottom: 1px solid #E6E6E6;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.20);
            /*box-shadow: 0 0 20px 0 rgba(77, 87, 93, 0.3);*/
            .container {
                max-height: calc(100vh - 120px);
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;

                .mega-row {
                    display: flex;
                    justify-content: center;
                    /* width: 50%; */
                    flex-wrap: wrap;

                    .group-container {
                        flex-basis: 23%;
                        margin: 10px;
                        display: flex;


                        .menu-group {
                            /*width: 100%;*/
                            /*flex-grow: 1;*/
                            padding: $space-md;
                            background-color: $color-white;
                            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), inset 0 1px 0 0 rgba(0,0,0,0.1);
                            margin: 0 0 2% 0;
                            /*opacity: 0;*/
                            animation: fadeIn 0.3s ease-out both;
                            /*flex-basis: 19%;*/
                            transition: all .3s ease-in-out;
                            width: 100%;

                            .group-heading {
                                @include typo(sm);
                                border-bottom: 1px solid $color-grey-dark;
                                display: block;
                                transition: all .3s ease-in-out;
                                text-transform: uppercase;
                                letter-spacing: 2px;
                            }

                            &:hover {
                                background-color: $color-grey;

                                .group-heading {
                                    color: $color-green;
                                    border-bottom: 1px solid $color-green;
                                }
                            }
                        }
                    }

                    &.brands {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        -webkit-flex-flow: row wrap;
                        justify-content: space-around;
                        padding-bottom: $space-xl;

                        .brand {
                            position: relative;
                            background-color: $color-white;
                            flex-basis: unset;
                            background-position: center center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-size: 70% auto;
                            padding: 5px;
                            width: 150px;
                            margin: 0 auto;
                            height: 100px;
                            margin-top: 10px;
                            line-height: 100px;
                            text-align: center;
                            transition: all .3s ease-in-out;
                            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
                            font-size: 25px;
                            padding: 0 $space-md;
                            animation: fadeIn 0.3s ease-out both;

                            &:before {
                                position: absolute;
                                left: 0;
                                width: 150px;
                                height: 100px;
                                display: block;
                                max-height: 600px;
                                background-color: $color-purple;
                                content: "";
                                opacity: 0;
                                transition: all .3s ease-in-out;
                            }

                            &:hover {
                                margin-top: $space-xs;

                                &:before {
                                    opacity: 0.2;
                                }
                            }
                        }
                    }
                }



                .group-item {
                    color: $color-grey-dark;
                    display: block;
                    margin-top: $space-sm/2;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: $color-black;
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(-5%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
