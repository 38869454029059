﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.latest-products {
    .product-item {
        margin-top: $space-lg;

        a {
            /*position:relative;
            display: block;
            padding: $space-md;
            background-color: $color-white;*/
            .product-image {
                background-color: $color-white;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                margin-bottom: $space-sm;

                @include media-breakpoint-up(sm) {
                    margin-bottom: $space-lg;
                }

                .badge-new {
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    padding: $space-xs;
                    border-radius: 50%;
                    color: #FFF;
                    background-color: #000;

                    @include media-breakpoint-up(sm) {
                        padding: 20px;
                    }
                }
            }

            .product-brand {
                margin-bottom: $space-sm;
            }
        }

        h2 {
            @include typo(sm);
            text-transform: uppercase;
        }

        p {
            line-height: 1;
        }
    }
}
