﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.header-cart-container {
    position: absolute;
    right: $space-xl*2;
    top: 23px;

    @include media-breakpoint-up(sm) {
        left: $space-xl;
        top: $space-md;
    }
    a{
        padding-left: 30px;
        background-image: url(/Assets/svg/cart.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-size: 25px 25px;
    }
}