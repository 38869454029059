﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.banner-module {
    margin-top: $space-xl*2;
    margin-bottom: $space-xl;

    .banner {
        position: relative;
        background-position: center center;
        width: 100%;
        height: 70vh;
        max-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .3s ease-in-out;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            height: 60vh;
        }

        &:before {
            position: absolute;
            width: 100%;
            height: 80vh;
            display: block;
            max-height: 600px;
            background-color: $color-purple;
            content: "";
            opacity: 0.2;
            transition: all .3s ease-in-out;

            @include media-breakpoint-up(sm) {
                height: 60vh;
            }
        }

        span {
            position: relative;
            display: block;
            color: $color-white;
            font-size: 8vmin;
            line-height: 1;
            text-transform: uppercase;
        }

        &:hover {
            &:before {
                background-color: $color-green;
            }
        }
    }
}
