﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.modules {
    margin-top: $space-sm;
    @include media-breakpoint-up(sm) {
        margin-top: $space-lg;
    }
    .product-list{
        .btn{
            margin-top: $space-md;
        }
    }
    h2{
        margin-bottom:$space-md;
    }
}

@import '../modules/latestproducts';
@import '../modules/bannermodule';
@import '../modules/brandmodule';
