﻿/// <reference path="../imports/variables" />
/// <reference path="../imports/mixins" />
/// <reference path="../imports/typography" />
/// <reference path="../../../node_modules/bootstrap/scss/mixins" />
.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-white;
    padding: $space-md 0;
    z-index: 50;
    border-left: 1px solid $color-grey;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);

    a {
        text-decoration: underline;
    }

    .btn {
        text-decoration: none;
    }
}